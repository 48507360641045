.whyccsContainer {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #222132;
  background-image: url("../Assets/abstractWallpaper.png");
}

.whyccsSection {
  width: 80%;
  margin: 0px auto;
}

.mainHeading {
  color: white;
  font-size: 3.5vw;
  font-family: "Montserrat";
  font-weight: 600;
}

.whyccsList {
  padding-top: 5vw;
}

.whyccsItem {
  display: flex;
  gap: 2vw;
  align-items: center;
  margin-bottom: 2vw;
}
.spacer {
  display: flex;
  align-items: center;
  height: 8vw;
}

.heading {
  color: white;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.381);
  text-transform: uppercase;
  font-size: 2.7vw;
  font-family: Montserrat;
  margin-bottom: 20px;
  margin-top: 0;
}

.content {
  color: white;
  font-family: "Segoe UI";
  font-size: 2.2vw;
}

.whyccsImage {
  height: 18vw;
  margin: 25px;
}

.whyccsItem:nth-child(odd) {
  text-align: left;
}

.whyccsItem:nth-child(even) {
  text-align: right;
}

.whyccsItem:last-child {
  margin-bottom: 10vw;
}

@media (max-width: 768px) {
  /* .whyccsItem {
    margin-bottom: 2vw;
  } */

  .whyccsItem:nth-child(odd) {
    flex-direction: column;
  }
  
  .whyccsItem:nth-child(even) {
    flex-direction: column-reverse;
  }

  .heading {
    text-align: center;
    font-size: 4vw;
  }

  .content {
    text-align: justify;
    font-size: 3vw;
  }
}