.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .flex-row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .select, .input, .textarea {
    border: 1px solid #ccc;
    border-radius: 0.375rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
  }
  
  .select {
    padding: 0.5rem;
  }
  
  .input {
    width: 100%;
  }
  
  .textarea-container, .input-container {
    margin-bottom: 1rem;
  }
  
  .textarea {
    width: 100%;
    padding: 0.5rem;
  }
  
  .options-container {
    margin-bottom: 1rem;
  }
  
  .option-input {
    margin-bottom: 0.5rem;
  }
  
  .button {
    background-color: #1d4ed8;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  
  .generate-button {
    background-color: #1d4ed8;
  }
  
  .add-button {
    background-color: #10b981;
  }
  
  .add-question-button {
    background-color: #8b5cf6;
  }
  
  .next-button {
    background-color: #1d4ed8;
    margin-top: 1rem;
  }
  
  .questions-container {
    margin-top: 2rem;
  }
  
  .subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .question {
    font-weight: bold;
  }
  
  .options-list {
    list-style-type: disc;
    padding-left: 1.25rem;
  }
  
  .correct-answer {
    font-weight: bold;
  }
  