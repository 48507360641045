.navbar {
    padding: 1rem 0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    color: #1d4ed8;
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .nav-links {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .nav-link {
    color: black;
    text-decoration: none;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-button {
    color: black;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
  .dropdown-menu {
    position: absolute;
    right: 0;
    margin-top: 0.5rem;
    width: 5rem;
    background: white;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 0.25rem 0;
  }
  
  .dropdown-item {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: black;
    text-decoration: none;
    display: block;
  }
  
  .logout-button {
    background-color: #1d4ed8;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  