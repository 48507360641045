.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 40px 0 0 40px;
  padding: 12px;
  
}

.leftContainerForm {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 70%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;

  color: #444;
}

.leftContainerForm::-webkit-scrollbar {
  width: 10px;
}

.leftContainerForm::-webkit-scrollbar-track {
  background-color: transparent;
}

.leftContainerForm::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.leftContainerForm::-webkit-scrollbar-thumb:hover {
  background-color: #444;
}
.logo {
  height: 18%;
}

.heading {
  margin: 0px;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  /* margin-bottom: 20px; */
  text-align: center;
}

.inputBox {
  display: block;
  margin-bottom: 20px;
  /* padding-left: 5px; */
  padding-bottom: 5px;
  margin-right: 3px;
  margin-left: 3px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s ease; 
}

.inputBox:focus {
  outline: none;
  border-bottom: 1px solid #a8bbbf; 
}

.checkboxes {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  margin: 20px 0;
}

.imgfield{
  display: block;
  margin-bottom: 20px;
  /* padding-left: 5px; */
  padding-bottom: 5px;
}
  

.checkHeading {
  margin-bottom: 10px;
}
.check {
  margin: 5px 0;

}
.check span{
  padding: 4px;
}

.check span:hover{
cursor: pointer;

}

.check input:hover {
  cursor: pointer;
} 

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #090963;
  border: none;
  color: white;
  padding: 6px 6px;
  font-size: 16px;
  font-family: "Montserrat";
  /* margin: 5px; */
  margin-top: 20px;
  height: 35px;
  transition: 0.2s;
}
.button:hover {
  background-color: #e6f2ff;
  color: black;
}

.arrow {
  position: absolute;
  right: 10px;
}

@media (max-width: 768px) {
  .leftContainer {
    border-radius: 40px;
    width: 100%;
    padding: 20px;
  }

  .leftContainerForm {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    
  }
  .leftContainerForm::-webkit-scrollbar {
    width: 10px;
  }
  
  .leftContainerForm::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .leftContainerForm::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: content-box;
  }
  
  .leftContainerForm::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  
}

.message{
  color:red
}



.cameraContainer {
  margin-top: 10px;
}

.webcam {
  width: 100%;
  max-width: 400px;
}

.captureButton {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #444;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}