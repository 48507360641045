
 .timer {
    display: inline-block;
    margin-top: 25px;
    text-align: center;
    width: 500px;
    /* border: solid 1px rgb(34, 46, 111);
    border-radius: 40px; */
  }
  .col {
    width: 25%;
    float: left;
    padding: 2px;;
  }
  .quiz{
    color:rgb(34, 46, 111)
  }
  .box {
    border: solid 1px rgb(34, 46, 111);
    border-radius: 40px;
    font-weight: 300;
    color:rgb(34, 46, 111);
    padding: 8px;
    padding-left: 0;
    padding-right:0 ;
  }
  .box p {
    font-size: calc(16px + 2vmin);
    margin: 0;
  }
  
  .text {
    font-size: 14px
  }

  .startTest {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    margin-top: 40px;
    width: 150px;
    height: 70px;
    background-color:#091029;
    color: white;
    border-radius: 30px;
    border: none;
    cursor: pointer;
  }

  .startTest:hover {
    background-color: green;
    color: white;
  }

  .message {
    margin-top: 20px;
    color: red;
  }